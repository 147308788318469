import React, { Component } from 'react'
import { x } from '@xstyled/styled-components'

class NotFound extends Component {
  UNSAFE_componentWillMount () {
    const { staticContext } = this.props
    if (staticContext) {
      staticContext.is404 = true
    }
  }

  render () {
    const props = this.props
    return (
      <>
        <x.div w='100%' maxWidth={1220} mx='auto' my={50} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
          <x.h1 text='h1' pb={20}>404 Page not found</x.h1>
        </x.div>
      </>
    )
  }
}

export default NotFound
