import React, { Component, Fragment, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { x } from '@xstyled/styled-components'

import { fetchStaticPage } from '../../store/staticPage'
import { staticPageView, setPageType } from '../../store/app'
import { getVisitorId, getSessionId } from '../../store/article'
import Helmet from 'react-helmet'
import Ad from '../components/Ad'
import {FORMATS, MAPPINGS} from '../components/AdFormats'
import NotFound from './NotFound'

export class StaticPage extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.pageName !== this.props.page) {
      this.props.fetchStaticPage(this.props.page)
    }
  }

  componentDidMount() {
    this.props.staticPageView(this.props.location.pathname, this.props.visitorId, this.props.sessionId)
    setPageType('section')
    if (!this.props.hasError) {
        this.props.fetchStaticPage(this.props.page)
    }
  }

  render() {
    if (this.props.hasError) {
      return (
        <>
          <x.main px={20} mt={{ sm: '96px', lg: '173px' }} w='100%'>
            <Helmet title='ERROR' />
            <x.div w='100%' maxWidth={1220} mx='auto' my={50}>
              <p>Error: {this.props.error}</p>
            </x.div>
          </x.main>
        </>
      )
    }
    if (this.props.hasFetched && this.props.staticPage) {
      let page = this.props.staticPage

      if (page && page.title) {
        return (
          <>
            <x.main px={20} mt={{ sm: '96px', lg: '173px' }} w='100%'>
              <Helmet title={this.props.title} >
                <meta name='description' content={this.props.description} />
              </Helmet>
              <Ad isConnected={this.props.isConnected} adPath={this.props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
              <x.div w='100%' maxWidth={1220} mx='auto' my={50}>
                <x.h1 text='h1' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>{this.props.sectionLabel || page.title}</x.h1>
                <div className='static-pages' dangerouslySetInnerHTML={{ __html: page.body }} />
                {page.newspapers &&
                  <x.div className='newspapers' display='grid' gridTemplateColumns={{ sm: 'repeat(2, calc((100% - 20px) / 2))', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ sm: '20', lg: '40' }}>
                    {page.newspapers.map(newspaper => (
                      <div>
                        <figure>
                          <a href={newspaper.newspaperPageUrl} target='_blank'>
                            <img src={newspaper.logo} title={newspaper.newspaperTitle} />
                          </a>
                        </figure>
                        <p>
                          <x.strong textTransform='uppercase' text='large'>{newspaper.newspaperTitle}</x.strong><br />
                          {newspaper.aboutLink && (
                            <span><a href={newspaper.aboutLink} target='_blank'>About</a> | </span>
                          )}
                          {newspaper.contactLink && (
                            <span><a href={newspaper.contactLink} target='_blank'>Contact</a><br /></span>
                          )}
                          {newspaper.advertisingLink && (
                            <span><a href={newspaper.advertisingLink} target='_blank'>Advertise</a> | </span>
                          )}
                          {newspaper.eEditionSubscribeLink && (
                            <a href={newspaper.eEditionSubscribeLink} target='_blank'>Subscribe</a>
                          )}
                        </p>
                      </div>
                    ))}
                  </x.div>
                }
              </x.div>
            </x.main>
          </>
        )
      } else {
        return (
          <>
            <Ad isConnected={this.props.isConnected} adPath={this.props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
            <Ad isConnected={this.props.isConnected} adPath={this.props.page} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert advert-leaderboard-top' mapping={MAPPINGS['Leaderboard']} {...FORMATS.leaderboardtop} />
            <NotFound />
            <Ad isConnected={this.props.isConnected} adPath={this.props.page} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert advert-leaderboard-bot' mapping={MAPPINGS['Leaderboard']} {...FORMATS.leaderboard} />
          </>
        )
      }
    } else {
      return (
        <>
          <Helmet title='Article' />
          <x.main px={20} mt={{ sm: '96px', lg: '173px' }} w='100%'>
            <x.div w='100%' maxWidth={1220} mx='auto' mt={50} mb={30}>Loading...</x.div>
          </x.main>
        </>
      )
    }
  }
}
const mapStateToProps = (state) => ({ ...state.staticPage, visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchStaticPage, staticPageView }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage)
